import React from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import "lazysizes"

import { graphql } from "gatsby"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import Container from "@material-ui/core/Container"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/SEO"

const InvestigationsPage = ({
  data: { investigationsPage },
  pageContext: { locale },
  location,
}) => {
  const schema = investigationsPage.data.schema_json.text || ""
  const ogp = investigationsPage.data.ogp_tags || ""
  const meta = {
    title: investigationsPage.data.meta_title,
    description: investigationsPage.data.meta_description,
    keywords: investigationsPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO schema={schema} ogp={ogp} meta={meta} locale={locale} />
      <Container maxWidth="lg">
        <main>
          {investigationsPage.data.sections.map(section => (
            <Accordion key={shortid.generate()}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{section.section_title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{
                    __html: section.section_content.html,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </main>
      </Container>
    </>
  )
}

export default InvestigationsPage

export const query = graphql`
  query InvestigationsPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    investigationsPage: prismicInvestigations(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        sections {
          section_title
          section_content {
            html
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }
  }
`

InvestigationsPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    investigationsPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
